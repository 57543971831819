html {
  background: #fff;
}

section {
  overflow-x: hidden;
}

#preloader,
.preloader-bg {
  display: none !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

a,
a:visited {
  color: #fff;
}

p {
  margin-bottom: .9rem;
}

h3.section-heading {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.04em;
  color: #000;
  padding-bottom: 10px;
  z-index: 1;
}

.c-btn {
  color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-btn-dark:hover {
  color: #fff !important;
}
.c-btn-dark:hover::before {
  background: #000 !important;
}


#map {
  width: 0vw;
  height: 50vh;
  transition: 0.3s ease-in-out;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.info-window {
  padding: 5px;

  img {
    float: right;
    width: 100%;
  }
}

//CSS for MAP modal

.showmap {
  background: none;
  border: none;
}

.clickable {
  cursor: pointer;
}

/* width */

::-webkit-scrollbar {
  width: 7px;
}
@media only screen and (max-width: 640px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $brand-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.facebook-div {
  padding: 15px;

  p {
    color: #fff;
    font-size: 1.2em;
    margin-top: 15px;
    font-family: "Raleway", sans serif;
  }

  a,
  a:visited,
  a:hover {
    color: $brand-color;
  }
}

.ion-social-facebook {
  font-size: 2em;
}

/* Center Spinner */

#preloader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hours h3 {
  font-size: 5.5rem;
}

@media only screen and (max-width: 640px) {
  .hours h3 {
    font-size: 3.5em;
  }
}

.gm-style-iw.gm-style-iw-c {
  max-width: 300px !important;
}

ul.social-icons a,
ul.social-icons a:visited {
  font-size: 2rem;
}

.social-icons-wrapper ul li:first-of-type {
  padding: 0 7px 0 0;
}

.social-icons-wrapper ul li {
  padding: 0 0 0 7px;
}

.owl-carousel {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
  padding: 30px;
}

.owl-nav {
  display: block !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.owl-stage-outer {
  margin-top: 16px;
}

.ion-icon {
  font-size: 2.5rem;
}

/* Featured Product */

#products {
  background: #fff;
  overflow: visible;
}

@media (min-width: 1200px) {
  #products {
    background: #fff;
    max-width: 1600px;
    width: 80%;
  }
  .wine-box #products {
    max-width: 100%;
    width: 100%;
  }
}

.featured {
  img {
    object-fit: contain;
    max-height: 450px;
    min-height: 100%;
  }
  h3.title {
    margin-top: 10px;
  }
  .title,
  h4 {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.04em;
    color: #000;
    padding-bottom: 10px;
    z-index: 1;
  }
  h4 {
    font-size: 1.2rem;
  }
  .description {
    font-size: 1rem;
  }

  .featuredProduct {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    background: $brand-color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 2px;
    padding: 5px 10px;
    font-weight: 500;
  }
}

/* Testimonial */

.testimonial {
  padding: 4rem 0;
  p {
    color: #fff;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50%;
    object-fit: cover;
  }
  blockquote {
    margin-left: 25px;
    .quote {
      font-size: 1.2rem;
      line-height: 1.7rem;
      position: relative;
      quotes: "“" "”";
      &:before {
        font-size: 8rem;
        line-height: 1;
        content: open-quote;
        position: absolute;
        top: -4rem;
      }
    }
    .author {
      margin-top: 15px;
      font-style: italic;
    }
  }
}

.services-txt p {
  color: #fff;
}

.services-txt-dark p {
  color: #5f5f5f;
}

.text-light,
.text-light p {
  color: #fff;
}

/* Wine Box */

.wine-box .wine-lovers {
  margin-top: 100px;
}
.wine-lovers {
  background: #fff;
  padding: 25px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0px 4px 14px 5px #42424263;
}
.wine-box .productSpan {
  width: 275px;
  max-width: 100%;
}
.wine-box .featured img {
  object-position: left;
  max-height: 100%;
}
.wine-box .featured .description {
  font-size: .8rem;
}

.owl-carousel.owl-drag .owl-item {
  display: flex;
  align-items: center;
  justify-content: center;
}