/*
  1. TEMPLATE BACKGROUNDS
    1.1. logo mobile IMG BACKGROUND
	1.2. single IMG BACKGROUND
    1.3. services IMG BACKGROUND
    1.4. launch IMG BACKGROUND
	1.5. ken burns slideshow IMG BACKGROUND
	1.6. slick fullscreen slideshow ZOOM/FADE IMG BACKGROUND
	1.7. home IMG carousel slider IMG BACKGROUND
	1.8. swiper parallax IMG BACKGROUND
	1.9. swiper timeline IMG BACKGROUND
	1.10. swiper thumbnail IMG BACKGROUND
	1.11. home IMG gallery carousel IMG BACKGROUND
	1.12. typed text IMG BACKGROUND
  2. reset
  3. layout
  4. upper page
  5. center container
  6. hero container
    6.1. hero bg
    6.2. hero fullscreen FIX
  7. intro
  8. overlay
  9. buttons
  10. preloader
  11. to top arrow
  12. navigation
    12.1. link underline menu
  13. vertical spacer line
  14. hours
  15. copyright
  16. link underline
  17. contact form
    17.1. contact form placeholders
    17.2. contact info
  18. newsletter form
    18.1. newsletter placeholders
  19. social icons
  20. sign up modal
  21. contact modal
  22. sections
    22.1. about section
    22.2. services section
      22.2.1. services section halves
    22.3. launch section
	22.4. contact section
  23. videos
    23.1. Vimeo video
    23.2. YouTube video
	23.3. HTML5 video
  24. ken burns slideshow
  25. scroll indicator
  26. Slick v1.6.0 CUSTOM
    26.1. slick slide
    26.2. slick slide flickering FIX
    26.3. slick navigation
    26.4. slick fullscreen slider
    26.5. slick fullscreen slideshow ZOOM/FADE transition
  27. Owl Carousel v2.2.0 CUSTOM
  28. home IMG carousel slider
  29. Swiper v3.4.2 CUSTOM
    29.1. swiper parallax
	29.2. swiper timeline
	29.3. swiper thumbnail
  30. home IMG gallery carousel
    30.1. home IMG gallery carousel items
  31. Magnific Popup v1.1.0 CUSTOM
  32. typed text
    32.1. typed text cursor
*/


/* 1. TEMPLATE BACKGROUNDS */
/* 1.1. logo mobile IMG BACKGROUND */

.header-navigation-xs .logo-holder {
background: url($baseurl + "/assets/images/HatLiquorLogoWhite.png") no-repeat center;
background-size: contain;
}

.header-navigation-xs .logo-holder.logo-holder-dark {
background: url($baseurl + "/assets/images/HatLiquorLogo.png") no-repeat center;
background-size: contain;
}


/* 1.2. single IMG BACKGROUND */
.bg-img-SINGLE {
background-image: url(../img/background/SINGLE-bg.jpg);
}


/* 1.3. services IMG BACKGROUND */
.services-bg-1 {
background-image: url($baseurl + "/assets/images/HatLiquor-8.jpg");
}
.services-bg-2 {
background-image: url($baseurl + "/assets/images/HatLiquor-25.jpg");
}


/* 1.4. launch IMG BACKGROUND */
.parallax-launch {
background-image: url($baseurl + "/assets/images/HatLiquor-29.jpg");
}


/* 1.5. ken burns slideshow IMG BACKGROUND */
/*
.kenburns-slide-1 {
background-image: url($baseurl + "/assets/images/HatLiquor-30.jpg");
}

.kenburns-slide-2 {
background-image: url($baseurl + "/assets/images/HatLiquor-10.jpg");
}

.kenburns-slide-3 {
background-image: url($baseurl + "/assets/images/HatLiquor-18.jpg");
}

.kenburns-slide-4 {
background-image: url($baseurl + "/assets/images/HatLiquor-7.jpg");
}
*/


/* 1.6. slick fullscreen slideshow ZOOM/FADE IMG BACKGROUND */
.bg-img-1 {
background-image: url(../img/background/hero-bg-1.jpg);
}

.bg-img-2 {
background-image: url(../img/background/hero-bg-2.jpg);
}

.bg-img-3 {
background-image: url(../img/background/hero-bg-3.jpg);
}

.bg-img-4 {
background-image: url(../img/background/hero-bg-4.jpg);
}


/* 1.7. home IMG carousel slider IMG BACKGROUND */
.home-page-img-item-1 {
background-image: url(../img/background/home-page-img-item-1.jpg);
}

.home-page-img-item-2 {
background-image: url(../img/background/home-page-img-item-2.jpg);
}

.home-page-img-item-3 {
background-image: url(../img/background/home-page-img-item-3.jpg);
}

.home-page-img-item-4 {
background-image: url(../img/background/home-page-img-item-4.jpg);
}

.home-page-img-item-5 {
background-image: url(../img/background/home-page-img-item-5.jpg);
}

.home-page-img-item-6 {
background-image: url(../img/background/home-page-img-item-6.jpg);
}

.home-page-img-item-7 {
background-image: url(../img/background/home-page-img-item-7.jpg);
}


/* 1.8. swiper parallax IMG BACKGROUND */
.swiper-slide-parallax-bg {
background-image: url("/assets/images/HatLiquor-1.jpg");
}


/* 1.9. swiper timeline IMG BACKGROUND */
.swiper-slide-bg-1 {
background-image: url(../img/background/timeline-bg-1.jpg);
}

.swiper-slide-bg-2 {
background-image: url(../img/background/timeline-bg-2.jpg);
}

.swiper-slide-bg-3 {
background-image: url(../img/background/timeline-bg-3.jpg);
}

.swiper-slide-bg-4 {
background-image: url(../img/background/timeline-bg-4.jpg);
}


/* 1.10. swiper thumbnail IMG BACKGROUND */
.thumbnail-slider-bg-1 {
background-image: url(../img/background/thumbnail-slider-bg-1.jpg);
}

.thumbnail-slider-bg-2 {
background-image: url(../img/background/thumbnail-slider-bg-2.jpg);
}

.thumbnail-slider-bg-3 {
background-image: url(../img/background/thumbnail-slider-bg-3.jpg);
}

.thumbnail-slider-bg-4 {
background-image: url(../img/background/thumbnail-slider-bg-4.jpg);
}

.thumbnail-slider-bg-5 {
background-image: url(../img/background/thumbnail-slider-bg-5.jpg);
}

.thumbnail-slider-bg-6 {
background-image: url(../img/background/thumbnail-slider-bg-6.jpg);
}

.thumbnail-slider-bg-7 {
background-image: url(../img/background/thumbnail-slider-bg-7.jpg);
}

.thumbnail-slider-bg-8 {
background-image: url(../img/background/thumbnail-slider-bg-8.jpg);
}


/* 1.11. home IMG gallery carousel IMG BACKGROUND */
.home-page-img-gallery-carousel-item-1 {
background-image: url(../img/gallery-slider/home-page-img-gallery-carousel-item-1.jpg);
}

.home-page-img-gallery-carousel-item-2 {
background-image: url(../img/gallery-slider/home-page-img-gallery-carousel-item-2.jpg);
}

.home-page-img-gallery-carousel-item-3 {
background-image: url(../img/gallery-slider/home-page-img-gallery-carousel-item-3.jpg);
}

.home-page-img-gallery-carousel-item-4 {
background-image: url(../img/gallery-slider/home-page-img-gallery-carousel-item-4.jpg);
}


/* 1.12. typed text IMG BACKGROUND */
.bg-split-content-img-1 {
background-image: url(../img/background/hero-split-content-bg-1.jpg);
}

.bg-split-content-img-2 {
background-image: url(../img/background/hero-split-content-bg-2.jpg);
}

.bg-split-content-img-3 {
background-image: url(../img/background/hero-split-content-bg-3.jpg);
}

.bg-split-content-img-4 {
background-image: url(../img/background/hero-split-content-bg-4.jpg);
}


/* 2. reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, /* img */, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}

html, body {
height: 100%;
}

body {
line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
display: block;
}

ol, ul {
list-style: none;
}

blockquote, q {
quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
content: "";
content: none;
}

table {
border-collapse: collapse;
border-spacing: 0;
}

*:focus {
outline: none;
}

a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
border: 0;
outline: 0;
}


/* 3. layout */
body {
font-family: 'Raleway', sans-serif;
font-size: 14px;
line-height: 1.5;
font-style: normal;
font-weight: 400;
text-align: left;
color: #5f5f5f;
background: #000;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
width: 100%;
height: 100%;
overflow-x: hidden;
overflow-y: auto;
margin: 0;
padding: 0;
}

a, button {
color: #5f5f5f;
text-decoration: none;
outline: none;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

a:hover,
a:visited,
a:active,
a:focus,
{
color: #5f5f5f;
text-decoration: none;
outline: none;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

p {
font-size: 14px;
color: #5f5f5f;
line-height: 2;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
}

p a {
color: #5f5f5f;
text-decoration: none;
font-weight: 700;
outline: none;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

p a:hover {
color: #5f5f5f;
text-decoration: none;
outline: none;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

p.p-light a,
p.p-light a:hover {
color: #fff;
}

strong {
font-weight: bold;
}

::-moz-selection,
::selection {
background: #000;
color: #fff;
}

.sections {
position: relative;
text-align: left;
z-index: 10;
}

.section-light {
background: #fff;
}

.section-dark {
background: #000;
}

.text-light {
color: #fff;
}

.text-dark {
color: #000;
}


/* 4. upper page */
.upper-page {
position: relative;
width: 100%;
height: 100%;
overflow: hidden;
left: 0;
top: 0;
margin: 0;
}


/* 5. center container */
.center-container {
position: absolute;
display: table;
table-layout: fixed;
width: 100%;
height: 100%;
background: none;
z-index: 1;
}

.center-block {
display: table-cell;
vertical-align: middle;
}

.center-container-contact-modal,
.center-container-sign-up-modal {
position: absolute;
display: table;
height: 100%;
width: 100%;
left: 0;
top: 0;
background: none;
}

.center-block-contact-modal,
.center-block-sign-up-modal {
display: table-cell;
vertical-align: middle;
}

.center-block-contact-modal-padding-top,
.center-block-sign-up-modal-padding-top {
padding: 60px 0 0 0;
}

.center-block-contact-modal-padding-bottom,
.center-block-sign-up-modal-padding-bottom {
padding: 0 0 60px 0;
}

.center-container-contact-modal h2.section-heading,
.center-container-sign-up-modal h2.section-heading {
position: relative;
font-family: 'Poppins', sans-serif;
font-size: 35px;
font-weight: 800;
text-transform: uppercase;
letter-spacing: -0.04em;
text-align: center;
color: #fff;
z-index: 1;
}

.center-container.center-container-split-content {
width: 50%;
left: 0;
background: #000;
}


/* 6. hero container */
.hero-fullscreen {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
overflow: hidden;
z-index: 0;
}

.hero-fullscreen-thumbnail-slider {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
overflow: hidden;
}


/* 6.1. hero bg */
.hero-bg {
position: relative;
width: 100%;
height: 100%;
background-position: center center;
background-repeat: no-repeat;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}


/* 6.2. hero fullscreen FIX */
.hero-fullscreen-FIX {
width: 100%;
height: 100%;
}


/* 7. intro */
h1.home-page-title {
position: relative;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 115px;
text-align: center;
text-transform: uppercase;
letter-spacing: -0.04em;
color: #fff;
margin: 21px auto 0 auto;
width: 100%;
line-height: 100%;
z-index: 1;
}

@media only screen and (max-width: 880px) {
  h1.home-page-title {
    font-size: 85px;
    margin: 25px auto 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  h1.home-page-title {
    font-size: 65px;
	margin: 18px auto 10px auto;
  }
}

h2.home-page-title {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 11px;
text-align: center;
text-transform: uppercase;
letter-spacing: 0.65em;
color: #fff;
margin: 0 0 0 12px;
}

h2.section-heading {
position: relative;
font-family: 'Poppins', sans-serif;
font-size: 50px;
font-weight: 800;
text-transform: uppercase;
letter-spacing: -0.04em;
color: #000;
padding-bottom: 10px;
z-index: 1;
}

@media only screen and (max-width: 640px) {
  h2.section-heading {
    font-size: 35px;
	padding-bottom: 5px;
  }
}

h2.section-heading.section-heading-light {
color: #fff;
}


/* 8. overlay */
.overlay:before {
content: "";
position: absolute;
display: block;
top: 0;
left: 0;
bottom: 0;
right: 0;
z-index: 2;
}

.overlay-top-bottom-dark-75:before {
background: -moz-linear-gradient(top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .1) 100%);
background: -webkit-linear-gradient(top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .1) 100%);
background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
-webkit-pointer-events: none;
   -moz-pointer-events: none;
        pointer-events: none;
}

.overlay-top-bottom-dark-75-video:before {
background: -moz-linear-gradient(top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .1) 100%);
background: -webkit-linear-gradient(top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .1) 100%);
background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
-webkit-pointer-events: auto;
   -moz-pointer-events: auto;
        pointer-events: auto;
}


/* 9. buttons */
.c-btn {
position: relative;
display: inline-block;
border: none;
background: none;
letter-spacing: 0.15em;
font-weight: 500;
font-size: 10px;
text-transform: uppercase;
text-align: center;
color: #fff;
margin: 20px 0 0 0;
padding: 5px 25px;
height: 40px;
-webkit-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   -moz-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
     -o-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
cursor: pointer;
}

.c-btn.fullwidth {
width: 200px;
background: $brand-color;
border: none;
}

.c-btn.c-btn-gallery {
width: 200px;
background: rgba(219, 0, 24, 1);
padding: 13px 25px 0 25px;
border: none;
}

.c-btn::before {
content: "";
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
background: rgba(255, 255, 255, 1);
-webkit-transform-origin: right center;
   -moz-transform-origin: right center;
    -ms-transform-origin: right center;
        transform-origin: right center;
-webkit-transform: scale(0, 1);
   -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
     -o-transform: scale(0, 1);
        transform: scale(0, 1);
-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   -moz-transition: -moz-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: -ms-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
     -o-transition: -o-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.c-btn:hover::before,
a:hover .c-btn::before {
-webkit-transform-origin: left center;
   -moz-transform-origin: left center;
    -ms-transform-origin: left center;
        transform-origin: left center;
-webkit-transform: scale(1, 1);
   -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
     -o-transform: scale(1, 1);
        transform: scale(1, 1);
}

.c-btn span {
display: inline-block;
position: relative;
z-index: 2;
}

.c-btn:hover,
a:hover .c-btn {
color: #000;
}


/* 10. preloader */
.preloader-bg {
position: fixed;
width: 100%;
height: 100%;
overflow: hidden;
background: #000;
z-index: 999999;
}

#preloader {
position: fixed;
display: table;
table-layout: fixed;
width: 100%;
height: 100%;
overflow: hidden;
background: #000;
z-index: 999999;
}

#preloader-status {
display: table-cell;
vertical-align: middle;
}

.preloader-position {
position: relative;
margin: 0 auto;
text-align: center;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
     -o-box-sizing: border-box;
        box-sizing: border-box;
}

.loader {
position: relative;
width: 45px;
height: 45px;
left: 50%;
top: 50%;
margin-left: -22px;
margin-top: 2px;
-webkit-animation: rotate 1s infinite linear;
   -moz-animation: rotate 1s infinite linear;
    -ms-animation: rotate 1s infinite linear;
     -o-animation: rotate 1s infinite linear;
        animation: rotate 1s infinite linear;
border: 3px solid rgba(255, 255, 255, .15);
-webkit-border-radius: 50%;
   -moz-border-radius: 50%;
    -ms-border-radius: 50%;
     -o-border-radius: 50%;
        border-radius: 50%;
}

.loader span {
position: absolute;
width: 45px;
height: 45px;
top: -3px;
left: -3px;
border: 3px solid transparent;
border-top: 3px solid rgba(255, 255, 255, .75);
-webkit-border-radius: 50%;
   -moz-border-radius: 50%;
    -ms-border-radius: 50%;
     -o-border-radius: 50%;
        border-radius: 50%;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* 11. to top arrow */
.to-top-arrow {
position: fixed;
right: 20px;
bottom: -10px;
width: 50px;
height: 50px;
line-height: 47px;
cursor: pointer;
font-size: 25px;
text-align: center;
color: #fff;
background: #000;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
-webkit-border-radius: 3px;
   -moz-border-radius: 3px;
        border-radius: 3px;
-webkit-transform: translateY(40px);
   -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
     -o-transform: translateY(40px);
        transform: translateY(40px);
-webkit-transition: all .6s ease;
   -moz-transition: all .6s ease;
    -ms-transition: all .6s ease;
     -o-transition: all .6s ease;
        transition: all .6s ease;
z-index: 10;
}

.to-top-arrow.show {
bottom: 0;
-webkit-transform: translateY(-20px);
   -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
     -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.to-top-arrow:hover {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

@media only screen and (min-width: 880px) {
  .to-top-arrow-bordered {
    right: 60px;
  }

  .to-top-arrow-bordered.show {
    bottom: 40px;
  }
}


/* 12. navigation */
.navigation-bg-light {
background: rgba(255, 255, 255, 1);
-webkit-transition: all .4s ease-out;
   -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
     -o-transition: all .4s ease-out;
        transition: all .4s ease-out;
-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .35);
   -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .35);
        box-shadow: 0 0 5px rgba(0, 0, 0, .35);
}

header {
position: fixed;
width: 100%;
-webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
z-index: 1000;
}

.logo-holder {
display: inline-block;
width: 80px;
height: 35px; margin-left: 20px;
-webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
}

.logo-holder img {
        width: 80px;
        height: 60px;
-webkit-transition: all .3s ease-in-out .3s;
    -ms-transition: all .3s ease-in-out .3s;
        transition: all .3s ease-in-out .3s;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        margin-left: -8px;
}

.logo-holder.closed {
width: 0; margin-left: -5px;
-webkit-transition: all .3s ease-in-out .3s;
    -ms-transition: all .3s ease-in-out .3s;
        transition: all .3s ease-in-out .3s;
}

.logo-holder.closed img {
-webkit-transition: all .3s ease-in-out 0s;
    -ms-transition: all .3s ease-in-out 0s;
        transition: all .3s ease-in-out 0s;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
-webkit-pointer-events: none;
   -moz-pointer-events: none;
        pointer-events: none;
}

.header-navigation {
height: 68px;
}

.menu-first-child {
margin-right: -20px;
}

.menu-second-child {
margin-right: -20px;
}

.menu-last-child {
margin-left: -20px;
}
.header-navigation>a,.header-navigation>button {
display: inline-block;
font-size: 11px;
font-weight: 600;
text-transform: uppercase;
letter-spacing: 0.15em;
padding: 27px 20px 0 20px;
color: #fff;
}


.header-navigation-dark>a, .header-navigation-dark>button {
color: #000;
}

.header-navigation-xs {
height: 90px;
}

.header-navigation-xs>a, .header-navigation-xs>button {
display: inline-block;
font-size: 10px;
font-weight: 600;
text-transform: uppercase;
letter-spacing: 0.15em;
padding: 0 5px;
color: #fff;
}

.header-navigation-xs.header-navigation-xs-dark>a, .header-navigation-xs-dark>button {
color: #000;
}

.header-navigation-xs .logo-holder {
position: relative;
display: block;
height: 50px;
margin: 15px auto 9px auto;
text-align: center;
}


/* 12.1. link underline menu */
.link-underline-menu {
position: relative;
text-decoration: none;
cursor: pointer;
}

.link-underline-menu:before {
content: "";
position: absolute;
width: 80%;
height: 1px;
bottom: -20px;
left: 10px;
right: auto;
background: #fff;
visibility: hidden;
-webkit-transform: scaleX(0);
   -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
     -o-transform: scaleX(0);
        transform: scaleX(0);
-webkit-transition: all 0.4s ease-in-out 0s;
   -moz-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
     -o-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
}

.link-underline-menu.link-underline-menu-dark:before {
bottom: -10px;
background: #000;
}

.link-underline-menu:hover:before {
visibility: visible;
-webkit-transform: scaleX(1);
   -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
     -o-transform: scaleX(1);
        transform: scaleX(1);
}

@media only screen and (max-width: 768px) {
  .link-underline-menu:hover:before {
    visibility: hidden;
  }
}


/* 13. vertical spacer line */
.spacer-line-vertical-dark,
.spacer-line-vertical-light,
.spacer-line-vertical-dark-modal,
.spacer-line-vertical-light-modal {
position: relative;
display: block;
}

.spacer-line-vertical-dark::after,
.spacer-line-vertical-light::after,
.spacer-line-vertical-dark-modal::after,
.spacer-line-vertical-light-modal::after {
content: "";
position: absolute;
width: 1px;
height: 70px;
top: 100%;
left: 50%;
}

.spacer-line-vertical-dark::after,
.spacer-line-vertical-dark-modal::after {
border-right: 1px solid #5f5f5f;
}

.spacer-line-vertical-light::after,
.spacer-line-vertical-light-modal::after {
border-right: 1px solid #fff;
}

.spacer-line-vertical-dark::after,
.spacer-line-vertical-light::after {
margin: 20px auto 0 auto;
}

@media only screen and (max-width: 640px) {
  .spacer-line-vertical-dark::after,
  .spacer-line-vertical-light::after {
    margin: 18px auto 0 auto;
  }
}

.spacer-line-vertical-dark-modal::after,
.spacer-line-vertical-light-modal::after {
margin: 23px auto 0 auto;
}


/* 14. hours */
.hours {
position: relative;
width: auto;
height: auto;
margin: 0 auto;
}

.hours-top,
.hours-bottom {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 11px;
text-align: center;
text-transform: uppercase;
letter-spacing: 0.65em;
color: #fff;
}

.hours-top {
margin-bottom: -7px;
margin-top: 126px;
}

@media only screen and (max-width: 640px) {
  .hours-top {
    margin-bottom: -10px;
  }
}

.hours-bottom {
margin-top: 18px;
}

@media only screen and (max-width: 640px) {
  .hours-bottom {
    margin-top: 15px;
  }
}

.hours h3 {
position: relative;
font-family: 'Montserrat', Arial, sans-serif;
font-style: normal;
font-weight: 700;
font-size: 7em;
text-align: center;
text-transform: uppercase;
letter-spacing: 0.05em;
color: #fff;
width: 100%;
line-height: initial;
span {
        font-size: 12%;
    }
}

@media only screen and (max-width: 640px) {
  .hours h3 {
    font-size: 3.5em;
  }
}


/* 15. copyright */
.copyright {
position: relative;
margin: 0 auto;
text-align: center;
font-size: 9px;
text-transform: uppercase;
margin-top: 35px;
margin-bottom: 15px;
	img {
			height: 35px;
			margin-top: 15px;
			padding-left: 5px;
			}
}

@media only screen and (max-width: 880px) {
  .copyright {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .copyright {
    margin-top: 35px;
  }
}

.copyright,
.copyright a,
.copyright a:hover {
color: #fff;
}


/* 16. link underline */
.link-underline {
position: relative;
display: inline-block;
}

.link-underline::before {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 1px;
background: #fff;
-webkit-transform-origin: right center;
   -moz-transform-origin: right center;
    -ms-transform-origin: right center;
        transform-origin: right center;
-webkit-transform: scale(0, 1);
   -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
     -o-transform: scale(0, 1);
        transform: scale(0, 1);
-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   -moz-transition: -moz-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: -ms-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
     -o-transition: -o-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.link-underline.link-underline-dark::before {
background: #5f5f5f;
}

.link-underline:hover::before {
-webkit-transform-origin: left center;
   -moz-transform-origin: left center;
    -ms-transform-origin: left center;
        transform-origin: left center;
-webkit-transform: scale(1, 1);
   -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
     -o-transform: scale(1, 1);
        transform: scale(1, 1);
}


/* 17. contact form */
#delivery-form {
width: 100%;
margin: 99px 0 0 0;
padding: 0;
text-align: center;
color: #fff;
    p {
        color: #fff;
    }
    hr {
        width: 50px;
        margin:15px auto 5px auto;
    }
}

form {
margin: 0;
padding: 0;
}

#form input {
position: relative;
width: 100%;
height: 40px;
border-bottom: 1px solid #fff;
border-left: none;
border-right: none;
border-top: none;
padding: 5px 5px;
background: none;
margin: 10px 0 10px 0;
font-family: 'Raleway', sans-serif;
font-size: 13px;
line-height: 1.5;
font-style: normal;
font-weight: normal;
text-align: center;
color: #fff;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

textarea {
position: relative;
width: 100%;
height: 100px;
border-bottom: 1px solid #fff;
border-left: none;
border-right: none;
border-top: none;
padding: 5px 5px;
background: none;
margin: 5px;
font-family: 'Raleway', sans-serif;
font-size: 13px;
line-height: 1.5;
font-style: normal;
font-weight: normal;
text-align: center;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

#form textarea {
color: #fff;
margin: 10px 0 10px 0;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

#form input:hover,
#form textarea:hover {
border-color: rgba(219, 0, 24, .5);
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}


#form input:focus,
#form textarea:focus {
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

.success {
font-family: 'Raleway', sans-serif;
font-size: 9px;
font-style: normal;
font-weight: 500;
letter-spacing: 0.15em;
text-transform: uppercase;
text-align: center;
color: #fff;
margin: 0 auto;
padding: 56px 0 60px 0;
line-height: 1;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

#form .error {
position: absolute;
font-size: 9px;
text-transform: uppercase;
text-align: left;
color: #db0018;
display: block;
margin: -2px 0 0 1px;
padding: 0;
letter-spacing: 0.15em;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.make-space {
margin-left: 15px;
margin-right: 15px;
}


/* 17.1. contact form placeholders */
::-webkit-input-placeholder { color: #fff; }
     :-ms-input-placeholder { color: #fff; }
         ::-moz-placeholder { color: #fff; }
     input:-moz-placeholder { color: #fff; }

input:focus::-webkit-input-placeholder { color: transparent; }
     input:focus:-ms-input-placeholder { color: transparent; }
         input:focus::-moz-placeholder { color: transparent; }
          input:focus:-moz-placeholder { color: transparent; }

textarea:focus::-webkit-input-placeholder { color: transparent; }
     textarea:focus:-ms-input-placeholder { color: transparent; }
         textarea:focus::-moz-placeholder { color: transparent; }
          textarea:focus:-moz-placeholder { color: transparent; }


/* 17.2. contact info */
.contact-info-wrapper {
position: relative;
width: 100%;
margin: 86px 0 0 0;
}

@media only screen and (max-width: 880px) {
  .contact-info-wrapper {
    margin: 94px 0 0 0;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-info-wrapper {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .contact-info-wrapper {
    margin: 98px 0 0 0;
  }
}

.contact-info-wrapper a,
.contact-info-wrapper a:hover {
color: #fff;
}

.contact-info-description {
color: #fff;
text-align: center;
}

.contact-info-description-img {
display: block;
font-size: 40px;
text-align: center;
color: #777;
margin: 15px 0 15px 0;
}

@media only screen and (max-width: 880px) {
  .contact-info-description-img {
    font-size: 35px;
	margin: 10px 0 10px 0;
  }
}

.contact-info-text {
letter-spacing: 0.05em;
}


/* 18. newsletter form */
#subscribe-wrapper {
position: relative;
width: 300px;
height: 160px;
margin: 109px auto 0 auto;
background: none;
text-align: center;
}

.newsletter {
position: relative;
clear: both;
border: none;
background: none;
padding: 0;
overflow: hidden;
}

#subscribe input {
position: relative;
width: 200px;
height: 40px;
font-size: 13px;
text-align: center;
color: #fff;
border: none;
border-bottom: 1px solid #fff;
background: none;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

#subscribe input:focus, #subscribe textarea:focus {
color: #fff;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

#subscribe input:hover {
border-color: rgba(119, 119, 119, .5);
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

.subscribe-success {
font-family: 'Raleway', sans-serif;
font-size: 9px;
font-style: normal;
font-weight: 500;
letter-spacing: 0.15em;
text-transform: uppercase;
text-align: center;
color: #fff;
margin: 0 auto;
padding: 46px 0 0 0;
line-height: 1;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

#subscribe .subscribe-error {
font-size: 9px;
text-transform: uppercase;
text-align: center;
color: #fff;
display: block;
margin: 8px auto 0 auto;
padding: 0;
letter-spacing: 0.15em;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}


/* 18.1. newsletter placeholders */
input[type="text"].subscribe-email::-webkit-input-placeholder {
color: #fff;
}

input[type="text"].subscribe-email:-ms-input-placeholder{
color: #fff;
}

input[type="text"].subscribe-email::-moz-placeholder {
color: #fff;
}

input:focus.subscribe-email::-webkit-input-placeholder { color: transparent; }
     input:focus.subscribe-email:-ms-input-placeholder { color: transparent; }
         input:focus.subscribe-email::-moz-placeholder { color: transparent; }
          input:focus.subscribe-email:-moz-placeholder { color: transparent; }


/* 19. social icons */
.social-icons-wrapper {
position: relative;
text-align: center;
margin: -39px auto 36px auto;
}

@media only screen and (max-width: 640px) {
  .social-icons-wrapper {
    margin: -71px auto 0 auto;
  }
}

.social-icons-wrapper ul {
margin: 0 auto;
padding: 0;
list-style-type: none;
}

.social-icons-wrapper ul li {
display: inline-block;
margin: 0 auto;
padding: 0 0 0 5px;
}

ul.social-icons {
font-size: 20px;
line-height: 1;
margin: 0;
padding: 0;
position: relative;
}

ul.social-icons a, ul.social-icons a:visited {
padding: 0;
color: #fff;
text-decoration: none;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}

ul.social-icons a:hover {
padding: 0;
color: $brand-color;
text-decoration: none;
-webkit-transition: all 0.5s linear;
   -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
     -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
}


/* 20. sign up modal */
.sign-up-button {
width: 225px;
height: 40px;
text-align: center;
margin: 3px auto 0 auto;
cursor: pointer;
}

@media only screen and (max-width: 880px) {
  .sign-up-button {
    margin: 7px auto 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .sign-up-button {
    margin: -10px auto 0 auto;
  }
}

.sign-up-button-second-position {
width: 225px;
height: 40px;
text-align: center;
margin: 0 auto 40px auto;
padding-top: 7px;
cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .sign-up-button-second-position {
    margin-top: -10px;
  }
}

.sign-up-modal-closer {
position: relative;
width: 50px;
height: auto;
margin: 35px auto 0 auto;
font-size: 20px;
text-align: center;
color: #fff;
cursor: pointer;
}

.sign-up-modal {
position: fixed;
width: -webkit-calc(100% - 20px);
width: -moz-calc(100% - 20px);
width: calc(100% - 20px);
height: -webkit-calc(100% - 160px, 20px, 20px, 20px);
height: -moz-calc(100% - 160px, 20px, 20px, 20px);
height: calc(100% - 160px, 20px, 20px, 20px);
top: 80px;
right: 10px;
bottom: 10px;
left: 10px;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
        box-sizing: border-box;
visibility: hidden;
background: #000;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
-webkit-transform: scale(0.75);
   -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
     -o-transform: scale(0.75);
        transform: scale(0.75);
-webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
     -o-transition: all 1s ease;
        transition: all 1s ease;
overflow-x: hidden;
overflow-y: auto;
z-index: 1005;
}

.sign-up-modal.sign-up-modal-light {
background: #fff;
}

.sign-up-modal.close {
visibility: hidden;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.sign-up-modal.open {
width: -webkit-calc(100% - 20px);
width: -moz-calc(100% - 20px);
width: calc(100% - 20px);
height: -webkit-calc(100% - 160px, 20px, 20px, 20px);
height: -moz-calc(100% - 160px, 20px, 20px, 20px);
height: calc(100% - 160px, 20px, 20px, 20px);
top: 80px;
right: 10px;
bottom: 10px;
left: 10px;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
        box-sizing: border-box;
visibility: visible;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-transform: scale(1);
   -moz-transform: scale(1);
    -ms-transform: scale(1);
     -o-transform: scale(1);
        transform: scale(1);
        #map {
            width: 70%;
        }
}

@media only screen and (max-width: 640px) {
  .sign-up-modal,
  .sign-up-modal.open {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sign-up-modal.open {
      #map {
          width: 100vw;
          margin:5px auto 5px auto;
      }
  }
}


/* 21. contact modal */
.use-form-button {
width: 225px;
height: 40px;
text-align: center;
margin: 105px auto -2px auto;
cursor: pointer;
}

@media only screen and (max-width: 880px) {
  .use-form-button {
    margin: 55px auto -2px auto;
  }
}

@media only screen and (max-width: 640px) {
  .use-form-button {
    margin: 78px auto 2px auto;
  }
}

.contact-modal-closer {
position: relative;
width: 50px;
height: auto;
margin: 33px auto 0 auto;
font-size: 20px;
text-align: center;
color: #fff;
cursor: pointer;
}

.contact-modal-wrapper {
position: relative;
width: 50%;
margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .contact-modal-wrapper {
    width: 90%;
  }
}

.contact-modal {
position: fixed;
width: -webkit-calc(100% - 20px);
width: -moz-calc(100% - 20px);
width: calc(100% - 20px);
height: -webkit-calc(100% - 160px, 20px, 20px, 20px);
height: -moz-calc(100% - 160px, 20px, 20px, 20px);
height: calc(100% - 160px, 20px, 20px, 20px);
top: 80px;
right: 10px;
bottom: 10px;
left: 10px;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
        box-sizing: border-box;
visibility: hidden;
background: #000;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
-webkit-transform: scale(0.75);
   -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
     -o-transform: scale(0.75);
        transform: scale(0.75);
-webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
     -o-transition: all 1s ease;
        transition: all 1s ease;
overflow-x: hidden;
overflow-y: auto;
z-index: 1005;
}

.contact-modal.close {
visibility: hidden;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.contact-modal.open {
width: -webkit-calc(100% - 20px);
width: -moz-calc(100% - 20px);
width: calc(100% - 20px);
height: -webkit-calc(100% - 160px, 20px, 20px, 20px);
height: -moz-calc(100% - 160px, 20px, 20px, 20px);
height: calc(100% - 160px, 20px, 20px, 20px);
top: 80px;
right: 10px;
bottom: 10px;
left: 10px;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
        box-sizing: border-box;
visibility: visible;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-transform: scale(1);
   -moz-transform: scale(1);
    -ms-transform: scale(1);
     -o-transform: scale(1);
        transform: scale(1);
}

@media only screen and (max-width: 640px) {
  .contact-modal,
  .contact-modal.open {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}


/* 22. sections */
/* 22.1. about section */
.about-txt {
margin: 16px 0 0 0;
padding: 0 200px;
}

@media only screen and (max-width: 880px) {
  .about-txt {
    margin: 12px 0 0 0;
    padding: 0 46px;
  }
}

@media only screen and (max-width: 640px) {
  .about-txt {
    margin: 0;
    padding: 0 50px;
  }
}

.about-top {
margin-top: 120px;
}

@media only screen and (max-width: 880px) {
  .about-top {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 640px) {
  .about-top {
    margin-top: 29px;
  }
}

.about-bottom {
margin-top: 100px;
margin-bottom: 144px;
}

@media only screen and (max-width: 880px) {
  .about-bottom {
    margin-top: 100px;
    margin-bottom: 94px;
  }
}

@media only screen and (max-width: 640px) {
  .about-bottom {
    margin-bottom: 46px;
  }
}


/* 22.2. services section */
.services-txt,
.services-txt-dark {
margin: 126px 0 0 0;
padding: 0 147px;
}

@media only screen and (max-width: 880px) {
  .services-txt,
  .services-txt-dark {
    margin: 122px 0 0 0;
    padding: 0 95px;
  }
}

@media only screen and (max-width: 640px) {
  .services-txt,
  .services-txt-dark {
    margin: 110px 0 0 0;
    padding: 0 50px;
  }
}

.services-txt {
color: #fff;
}

.services-txt-dark {
color: #5f5f5f;
}

.services-top {
margin-top: 120px;
}

@media only screen and (max-width: 880px) {
  .services-top {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 640px) {
  .services-top {
    margin-top: 29px;
  }
}

.services-bottom {
margin-top: 100px;
margin-bottom: 144px;
}

@media only screen and (max-width: 880px) {
  .services-bottom {
    margin-top: 100px;
    margin-bottom: -6px;
  }
}

@media only screen and (max-width: 640px) {
  .services-bottom {
    margin-top: 0;
    margin-bottom: 46px;
  }
}

.services-bg-1,
.services-bg-2 {
width: 100%;
height: auto;
background-position: center center;
background-repeat: no-repeat;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
-webkit-filter: grayscale(100%);
        filter: grayscale(100%);
-webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
     -o-transition: all 1s ease;
        transition: all 1s ease;
}

.halves:hover .services-bg-1,
.halves:hover .services-bg-2 {
-webkit-filter: none;
        filter: none;
-webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
     -o-transition: all 1s ease;
        transition: all 1s ease;
}


/* 22.2.1. services section halves */
.halves {
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -moz-flex;
display: -webkit-flex;
display: flex;
}

.halves .half {
min-height: 350px;
-webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
}

@media (max-width: 768px) {
  .halves {
    -webkit-box-orient: vertical;
            box-orient: vertical;
	-webkit-box-direction: normal;
	        box-direction: normal;
	-webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .halves .half {
    -webkit-box-flex: 0;
            -ms-flex: none;
                flex: none;
  }
}


/* 22.3. launch section */
.parallax-overlay {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
background: rgba(0, 0, 0, .75);
z-index: 0;
}

.parallax {
display: block;
width: 100%;
height: 100%;
overflow: hidden;
padding: 129px 0 137px 0;
}

@media only screen and (max-width: 880px) {
  .parallax {
    padding: 79px 0 87px 0;
  }
}

@media only screen and (max-width: 640px) {
  .parallax {
    padding: 34px 0 42px 0;
  }
}

.parallax-launch {
position: relative;
background-repeat: no-repeat;
background-position: center center;
    -webkit-background-size: cover;
       -moz-background-size: cover;
        -ms-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
border: none;
z-index: 1;
}


/* 22.4. contact section */
.contact-top {
margin-top: 120px;
}

@media only screen and (max-width: 880px) {
  .contact-top {
    margin-top: 71px;
  }
}

@media only screen and (max-width: 640px) {
  .contact-top {
    margin-top: 29px;
  }
}

.contact-bottom {
margin-top: 100px;
margin-bottom: 35px;
}

@media only screen and (max-width: 880px) {
  .contact-bottom {
    margin-top: 100px;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .contact-bottom {
    margin-top: 100px;
    margin-bottom: 15px;
  }
}


/* 23. videos */
/* 23.1. Vimeo video */
#vimeo-video {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
overflow: hidden;
border: none;
}

#vimeo-video iframe,
#vimeo-video object,
#vimeo-video embed {
border: none;
}


/* 23.2. YouTube video */
.YT-bg {
background: none;
}

@media only screen and (max-width: 880px) {
  .YT-bg {
    position: relative;
    height: 100%;
    background-image: url(../img/background/YT-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
       -moz-background-size: cover;
        -ms-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
  }
}

.background-video {
position: absolute;
background-repeat: no-repeat;
background-position: top center;
top: 0;
right: 0;
bottom: 0;
left: 0;
overflow: hidden;
}

video,
source {
position: absolute;
min-width: 100%;
min-height: 100%;
bottom: 0;
left: 0;
}

.loaded .ytplayer-container {
display: block;
}

.ytplayer-container {
position: absolute;
min-width: 100%;
height: 100%;
top: 0;
right: 0;
bottom: 0;
left: 0;
overflow: hidden;
z-index: 1;
}

.ytplayer-shield {
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
z-index: 1;
}

.ytplayer-player {
position: absolute;
}


/* 23.3. HTML5 video */
#video-bg {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
overflow: hidden;
background: #000;
z-index: 0;
}


/* 24. ken burns slideshow */
.kenburns-slide-wrapper {
position: relative;
width: 100%;
height: 100%;
left: 0;
top: 0;
overflow: hidden;
z-index: 0;
}

.kenburns-slide {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
-webkit-animation: KenBurnsSlideShow 24s linear infinite 0s;
   -moz-animation: KenBurnsSlideShow 24s linear infinite 0s;
    -ms-animation: KenBurnsSlideShow 24s linear infinite 0s;
     -o-animation: KenBurnsSlideShow 24s linear infinite 0s;
        animation: KenBurnsSlideShow 24s linear infinite 0s;
}

.kenburns-slide-1,
.kenburns-slide-2,
.kenburns-slide-3,
.kenburns-slide-4 {
background-repeat: no-repeat;
background-position: center;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}

.kenburns-slide-1 {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.kenburns-slide-2 {
-webkit-animation-delay: 6s;
   -moz-animation-delay: 6s;
    -ms-animation-delay: 6s;
     -o-animation-delay: 6s;
        animation-delay: 6s;
}

.kenburns-slide-3 {
-webkit-animation-delay: 12s;
   -moz-animation-delay: 12s;
    -ms-animation-delay: 12s;
     -o-animation-delay: 12s;
        animation-delay: 12s;
}

.kenburns-slide-4 {
-webkit-animation-delay: 18s;
   -moz-animation-delay: 18s;
    -ms-animation-delay: 18s;
     -o-animation-delay: 18s;
        animation-delay: 18s;
}

@-webkit-keyframes KenBurnsSlideShow {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
 5% {
    opacity: 1
  }
 25% {
    opacity: 1;
  }
 30% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
 100% {
    opacity: 0;
    -webkit-transformm: scale(1);
  }
}

@-moz-keyframes KenBurnsSlideShow {
  0% {
    opacity: 0;
    -moz-transform: scale(1);
  }
 5% {
    opacity: 1
  }
 25% {
    opacity: 1;
  }
 30% {
    opacity: 0;
    -moz-transform: scale(1.1);
  }
 100% {
    opacity: 0;
    -moz-transform: scale(1);
  }
}

@-o-keyframes KenBurnsSlideShow {
  0% {
    opacity: 0;
    -o-transform: scale(1);
  }
 5% {
    opacity: 1
  }
 25% {
    opacity: 1;
  }
 30% {
    opacity: 0;
    -o-transform: scale(1.1);
  }
 100% {
    opacity: 0;
    -o-transform: scale(1);
  }
}

@keyframes KenBurnsSlideShow {
  0% {
    opacity: 0;
	    transform: scale(1);
    -ms-transform: scale(1);
  }
 5% {
    opacity: 1
  }
 25% {
    opacity: 1;
  }
 30% {
    opacity: 0;
	    transform: scale(1.1);
    -ms-transform: scale(1.1);
  }
 100% {
    opacity: 0;
	    transform: scale(1);
    -ms-transform: scale(1);
  }
}


/* 25. scroll indicator */
.scroll-indicator-wrapper {
position: absolute;
left: 50%;
bottom: 0;
z-index: 10;
}

.scroll-indicator {
position: absolute;
width: 1px;
height: 70px;
left: 0;
right: 0;
bottom: 0;
margin: auto;
background-color: rgba(191, 191, 191, .25);
mix-blend-mode: overlay;
}

@media only screen and (max-width: 640px) {
  .scroll-indicator {
    height: 35px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .scroll-indicator {
    display: none;
	visibility: hidden;
  }
}

.scroll-indicator::before {
content: "";
position: absolute;
display: block;
width: 100%;
height: 50%;
-webkit-animation: teardrop 2s cubic-bezier(.76, .05, .86, .06) infinite;
	    animation: teardrop 2s cubic-bezier(.76, .05, .86, .06) infinite;
-webkit-animation-direction: normal;
	    animation-direction: normal;
background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
background: linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@-webkit-keyframes teardrop {
  0% {
    top: -10%;
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
   }
  100% {
    top: 100%;
    height: 75%;
  }
}
@keyframes teardrop {
  0% {
    top: -10%;
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
   }
  100% {
    top: 100%;
    height: 75%;
  }
}


/* 26. Slick v1.6.0 CUSTOM */
/* 26.1. slick slide */
.slick-slide {
height: 100vh;
background: none;
}

.slick-slider {
margin-bottom: 0;
margin-top: 0;
cursor: auto;
}


/* 26.2. slick slide flickering FIX */
.slick-track,
.slick-list {
-webkit-perspective: 2000;
        perspective: 2000;
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
}


/* 26.3. slick navigation */
.slick-prev,
.slick-next {
position: absolute;
display: block;
top: 50%;
width: 56px;
height: 56px;
line-height: 56px;
text-align: center;
-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
   -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
     -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
z-index: 1;
visibility: visible;
}

.slick-prev {
left: -50px;
}

.slick-next {
right: -50px;
}

.slick-prev:before,
.slick-next:before {
font-size: 14px;
color: #fff;
padding-top: 10px;
-webkit-border-radius: 3px;
   -moz-border-radius: 3px;
        border-radius: 3px;
display: block;
width: 66%;
height: 66%;
position: absolute;
background: rgba(0, 0, 0, 1);
-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
   -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
     -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-prev:before {
left: 55px;
top: 9px;
}

.slick-next:before {
right: 55px;
top: 9px;
}

.slick-prev:hover,
.slick-next:hover {
-webkit-transition: 0 none;
   -moz-transition: 0 none;
	-ms-transition: 0 none;
     -o-transition: 0 none;
        transition: 0 none;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:active:before,
.slick-next:active:before {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-fullscreen:hover .slick-prev,
.slick-fullscreen:hover .slick-next,
.slick-fullscreen-slideshow:hover .slick-prev,
.slick-fullscreen-slideshow:hover .slick-next,
.slick-fullscreen-split:hover .slick-prev,
.slick-fullscreen-split:hover .slick-next,
.slick-fullscreen-slideshow-zoom-fade:hover .slick-prev,
.slick-fullscreen-slideshow-zoom-fade:hover .slick-next {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-fullscreen:hover .slick-prev,
.slick-fullscreen-slideshow:hover .slick-prev,
.slick-fullscreen-split:hover .slick-prev,
.slick-fullscreen-slideshow-zoom-fade:hover .slick-prev {
left: -35px;
}

.slick-fullscreen:hover .slick-next,
.slick-fullscreen-slideshow:hover .slick-next,
.slick-fullscreen-split:hover .slick-next,
.slick-fullscreen-slideshow-zoom-fade:hover .slick-next {
right: -35px;
}


/* 26.4. slick fullscreen slider */
.slick-fullscreen.slick-fullscreen-split-content {
width: 50%;
left: 50%;
}

.slick-fullscreen-img-fill {
position: relative;
display: block;
width: 100%;
overflow: hidden;
text-align: center;
}

.slick-fullscreen-img-fill img {
position: relative;
display: inline-block;
min-width: 100%;
max-width: none;
min-height: 100%;
}

.slick-fullscreen .slick-fullscreen-item .slick-fullscreen-img-fill,
.slick-fullscreen-slideshow .slick-fullscreen-item .slick-fullscreen-img-fill,
.slick-fullscreen-split .slick-fullscreen-item .slick-fullscreen-img-fill,
.slick-fullscreen-slideshow-zoom-fade .slick-fullscreen-item .slick-fullscreen-img-fill {
height: 100vh;
background: none;
}

.slick-fullscreen .slick-fullscreen-item .slick-fullscreen-img-fill .bg-img,
.slick-fullscreen-slideshow .slick-fullscreen-item .slick-fullscreen-img-fill .bg-img,
.slick-fullscreen-split .slick-fullscreen-item .slick-fullscreen-img-fill .bg-img,
.slick-fullscreen-slideshow-zoom-fade .slick-fullscreen-item .slick-fullscreen-img-fill .bg-img {
position: relative;
width: auto;
height: 100%;
background-position: center center;
background-repeat: no-repeat;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}

.slick-fullscreen .slick-dots,
.slick-fullscreen-slideshow .slick-dots,
.slick-fullscreen-split .slick-dots,
.slick-fullscreen-slideshow-zoom-fade .slick-dots {
position: absolute;
width: 100%;
height: 5px;
left: 0;
bottom: 0;
margin: 0;
padding: 0;
background: rgba(255, 255, 255, .25);
list-style-type: none;
}

.slick-fullscreen .slick-dots li button,
.slick-fullscreen-slideshow .slick-dots li button,
.slick-fullscreen-split .slick-dots li button,
.slick-fullscreen-slideshow-zoom-fade .slick-dots li button {
display: none;
}

.slick-fullscreen .slick-dots li,
.slick-fullscreen-slideshow .slick-dots li,
.slick-fullscreen-split .slick-dots li,
.slick-fullscreen-slideshow-zoom-fade .slick-dots li {
position: absolute;
float: left;
width: 0;
height: 5px;
left: -5px;
background: #fff;
}

.slick-fullscreen .slick-dots li.slick-active,
.slick-fullscreen-slideshow .slick-dots li.slick-active,
.slick-fullscreen-split .slick-dots li.slick-active,
.slick-fullscreen-slideshow-zoom-fade .slick-dots li.slick-active {
width: 100%;
-webkit-animation: progressDots 4s both;
   -moz-animation: progressDots 4s both;
    -ms-animation: progressDots 4s both;
     -o-animation: progressDots 4s both;
        animation: progressDots 4s both;
}

@-webkit-keyframes progressDots {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes progressDots {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.slick-fullscreen,
.slick-fullscreen-slideshow,
.slick-fullscreen-split,
.slick-fullscreen-slideshow-zoom-fade {
background: none;
}

.slick-fullscreen .slick-slide,
.slick-fullscreen-slideshow .slick-slide,
.slick-fullscreen-split .slick-slide,
.slick-fullscreen-slideshow-zoom-fade .slick-slide {
display: none;
float: left;
height: 100%;
}

.slick-initialized .slick-slide {
display: block;
}


/* 26.5. slick fullscreen slideshow ZOOM/FADE transition */
.slick-fullscreen-slideshow-zoom-fade .slick-fullscreen-item .bg-img {
-webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
   -moz-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    -ms-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
     -o-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
-webkit-transform: scale(1.3);
   -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
     -o-transform: scale(1.3);
        transform: scale(1.3);
}

.slick-fullscreen-slideshow-zoom-fade .slick-fullscreen-item.slick-active .bg-img {
-webkit-transform: scale(1);
   -moz-transform: scale(1);
    -ms-transform: scale(1);
     -o-transform: scale(1);
        transform: scale(1);
}


/* 27. Owl Carousel v2.2.0 CUSTOM */
.owl-buttons {
position: static;
}

.owl-prev,
.owl-next {
position: absolute;
display: block;
bottom: 0;
width: 56px;
height: 56px;
line-height: 56px;
font-size: 14px;
color: #000;
text-align: center;
-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
   -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
     -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
cursor: pointer;
}

.owl-prev {
left: -45px;
}

.owl-next {
right: -45px;
}

.owl-prev:before,
.owl-next:before {
content: "";
display: block;
width: 66%;
height: 66%;
position: absolute;
background: none;
border: none;
-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
   -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
     -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-border-radius: 3px;
   -moz-border-radius: 3px;
        border-radius: 3px;
}

.owl-prev:before {
left: 45px;
top: 9px;
}

.owl-next:before {
right: 45px;
top: 9px;
}

.owl-prev .owl-custom,
.owl-next .owl-custom {
position: relative;
}

.owl-prev .owl-custom {
position: relative;
left: 34px;
}

.owl-next .owl-custom {
position: relative;
right: 34px;
}

.owl-prev:hover,
.owl-next:hover {
-webkit-transition: 0 none;
   -moz-transition: 0 none;
	-ms-transition: 0 none;
     -o-transition: 0 none;
        transition: 0 none;
}

.owl-prev:hover,
.owl-next:hover,
.owl-prev:hover:before,
.owl-next:hover:before,
.owl-prev:active:before,
.owl-next:active:before,
.owl-carousel:hover .owl-prev,
.owl-carousel:hover .owl-next {
        opacity: 0.5;
   -moz-opacity: 0.5;
-webkit-opacity: 0.5;
filter: alpha(opacity=50);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.owl-carousel:hover .owl-prev {
left: -35px;
}

.owl-carousel:hover .owl-next {
right: -35px;
}

#home-page-img-carousel .owl-prev,
#home-page-img-carousel .owl-next,
#home-page-img-gallery-carousel .owl-prev,
#home-page-img-gallery-carousel .owl-next {
position: absolute;
display: block;
top: 50%;
margin-top: -29px;
width: 56px;
height: 56px;
line-height: 56px;
font-size: 14px;
color: #000;
text-align: center;
-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
   -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
     -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

#home-page-img-carousel.owl-prev,
#home-page-img-gallery-carousel.owl-prev {
left: -50px;
}

#home-page-img-carousel.owl-next,
#home-page-img-gallery-carousel.owl-next {
right: -50px;
}

#home-page-img-carousel .owl-prev:before,
#home-page-img-carousel .owl-next:before,
#home-page-img-gallery-carousel .owl-prev:before,
#home-page-img-gallery-carousel .owl-next:before {
content: "";
display: block;
width: 66%;
height: 66%;
position: absolute;
background: rgba(255, 255, 255, 1);
-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
   -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
     -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
-webkit-border-radius: 3px;
   -moz-border-radius: 3px;
        border-radius: 3px;
}

#home-page-img-carousel.owl-prev:before,
#home-page-img-gallery-carousel.owl-prev:before {
left: 45px;
top: 9px;
}

#home-page-img-carousel.owl-next:before,
#home-page-img-gallery-carousel.owl-next:before {
right: 45px;
top: 9px;
}

#home-page-img-carousel.owl-prev .owl-custom,
#home-page-img-carousel.owl-next .owl-custom,
#home-page-img-gallery-carousel.owl-prev .owl-custom,
#home-page-img-gallery-carousel.owl-next .owl-custom {
position: relative;
}

#home-page-img-carousel.owl-prev .owl-custom,
#home-page-img-gallery-carousel.owl-prev .owl-custom {
position: relative;
left: 34px;
}

#home-page-img-carousel.owl-next .owl-custom,
#home-page-img-gallery-carousel.owl-next .owl-custom {
position: relative;
right: 34px;
}

#home-page-img-carousel.owl-prev:hover,
#home-page-img-carousel.owl-next:hover,
#home-page-img-gallery-carousel.owl-prev:hover,
#home-page-img-gallery-carousel.owl-next:hover {
-webkit-transition: 0 none;
   -moz-transition: 0 none;
	-ms-transition: 0 none;
     -o-transition: 0 none;
        transition: 0 none;
}

#home-page-img-carousel .owl-prev:hover,
#home-page-img-carousel .owl-next:hover,
#home-page-img-carousel .owl-prev:hover:before,
#home-page-img-carousel .owl-next:hover:before,
#home-page-img-carousel .owl-prev:active:before,
#home-page-img-carousel .owl-next:active:before,
#home-page-img-carousel.owl-carousel:hover .owl-prev,
#home-page-img-carousel.owl-carousel:hover .owl-next,
#home-page-img-gallery-carousel .owl-prev:hover,
#home-page-img-gallery-carousel .owl-next:hover,
#home-page-img-gallery-carousel .owl-prev:hover:before,
#home-page-img-gallery-carousel .owl-next:hover:before,
#home-page-img-gallery-carousel .owl-prev:active:before,
#home-page-img-gallery-carousel .owl-next:active:before,
#home-page-img-gallery-carousel.owl-carousel:hover .owl-prev,
#home-page-img-gallery-carousel.owl-carousel:hover .owl-next {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

#home-page-img-carousel.owl-carousel:hover .owl-prev {
left: -35px;
}

#home-page-img-carousel.owl-carousel:hover .owl-next {
right: -35px;
}

#home-page-img-gallery-carousel.owl-carousel:hover .owl-prev {
left: -20px;
}

#home-page-img-gallery-carousel.owl-carousel:hover .owl-next {
right: -20px;
}


/* 28. home IMG carousel slider */
.home-page-img-wrapper {
position: relative;
width: 100%;
height: 100%;
}

#home-page-img .home-page-img-item {
padding: 0;
        opacity: 0.25;
   -moz-opacity: 0.25;
-webkit-opacity: 0.25;
filter: alpha(opacity=25);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
-webkit-transform: scale3d(0.8, 0.8, 1);
   -moz-transform: scale3d(0.8, 0.8, 1);
    -ms-transform: scale3d(0.8, 0.8, 1);
     -o-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
-webkit-transition: all .3s ease-in-out;
   -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
}

#home-page-img .owl-item.active.center .home-page-img-item {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-transform: scale3d(1.0, 1.0, 1);
   -moz-transform: scale3d(1.0, 1.0, 1);
    -ms-transform: scale3d(1.0, 1.0, 1);
     -o-transform: scale3d(1.0, 1.0, 1);
        transform: scale3d(1.0, 1.0, 1);
}

.home-page-img-item-1,
.home-page-img-item-2,
.home-page-img-item-3,
.home-page-img-item-4,
.home-page-img-item-5,
.home-page-img-item-6,
.home-page-img-item-7 {
position: relative;
width: 100%;
height: 100vh;
background-position: center center;
background-repeat: no-repeat;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}


/* 29. Swiper v3.4.2 CUSTOM */
.swiper-container {
position: absolute;
width: 100%;
height: inherit;
margin-left: auto;
margin-right: auto;
}

.swiper-slide {
background-repeat: no-repeat;
background-position: center center;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}


/* 29.1. swiper parallax */
.parallax .swiper-button-next,
.parallax .swiper-button-prev {
display: block;
visibility: visible;
width: 20px;
height: 20px;
background-size: 20px 20px;
display: none;
visibility: hidden;
}

.parallax a,
.parallax a:hover {
color: #fff;
}

.parallax .swiper-container {
width: 100%;
height: 100%;
background: #000;
left: 0;
top: 0;
}

.parallax .swiper-slide {
margin: 0;
padding: 0;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
        box-sizing: border-box;
}

.parallax .parallax-bg {
position: absolute;
left: 0;
top: 0;
width: 130%;
height: 100%;
background-position: center center;
background-repeat: no-repeat;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}


/* 29.2. swiper timeline */
.timeline .swiper-button-next,
.timeline .swiper-button-prev {
display: block;
visibility: visible;
}

.timeline-container a,
.timeline-container a:hover {
color: #fff;
}

.timeline-container {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
        box-pack: center;
-webkit-justify-content: center;
        justify-content: center;
-webkit-box-align: center;
   -ms-flex-align: center;
      align-items: center;
-webkit-box-orient: vertical;
        box-orient: vertical;
-webkit-box-direction: normal;
   -ms-flex-direction: column;
       flex-direction: column;
height: 100%;
background-color: #000;
}

.timeline {
width: 100%;
background-color: #000;
}

.timeline .swiper-container {
position: relative;
width: 100%;
height: 100vh;
}

.timeline .swiper-wrapper {
-webkit-transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
   -moz-transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
    -ms-transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
     -o-transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
        transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}

.timeline .swiper-slide {
position: relative;
color: #fff;
overflow: hidden;
background-repeat: no-repeat;
background-position: center center;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}

.timeline .swiper-slide::after {
content: "";
position: absolute;
display: block;
top: 0;
left: 0;
bottom: 0;
right: 0;
background: -moz-linear-gradient(right, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .1) 50%);
background: -webkit-linear-gradient(right, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .1) 50%);
background: linear-gradient(to left, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .1) 50%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
z-index: 1;
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-slide::after {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .1) 75%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .1) 75%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .1) 75%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
  }
}

.timeline .swiper-slide-content {
position: absolute;
width: 600px;
top: 50%;
right: 23%;
font-family: 'Raleway', sans-serif;
font-size: 12px;
line-height: 1.5;
font-style: normal;
font-weight: 400;
text-align: right;
color: #fff;
text-shadow: 1px 1px 2px #000;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
-webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
     -o-transform: translateY(-50%);
        transform: translateY(-50%);
z-index: 2;
}

@media only screen and (max-width: 1200px) {
  .timeline .swiper-slide-content {
	right: 25%;
  }
}

@media only screen and (max-width: 880px) {
  .timeline .swiper-slide-content {
	right: 30%;
  }
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-slide-content {
    max-width: 300px;
	top: 50%;
	margin-top: -115px;
	right: 50%;
	text-align: center;
    -webkit-transform: translate(50%, 0);
       -moz-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
         -o-transform: translate(50%, 0);
            transform: translate(50%, 0);
  }
}

.timeline .swiper-slide .timeline-year {
display: block;
font-family: 'Poppins', sans-serif;
font-size: 30px;
line-height: 1;
font-style: normal;
text-transform: uppercase;
font-weight: 400;
letter-spacing: normal;
color: #fff;
margin: 0;
-webkit-transform: translate3d(20px, 0, 0);
   -moz-transform: translate3d(20px, 0, 0);
    -ms-transform: translate3d(20px, 0, 0);
     -o-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
-webkit-transition: .2s ease .4s;
   -moz-transition: .2s ease .4s;
    -ms-transition: .2s ease .4s;
     -o-transition: .2s ease .4s;
        transition: .2s ease .4s;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

@media only screen and (max-width: 880px) {
  .timeline .swiper-slide .timeline-year {
	font-size: 20px;
  }
}

.timeline .swiper-slide .timeline-title {
font-family: 'Poppins', sans-serif;
font-size: 80px;
line-height: 1;
font-style: normal;
text-transform: uppercase;
font-weight: 700;
letter-spacing: -0.05em;
color: #fff;
margin: 25px 0;
-webkit-transform: translate3d(20px, 0, 0);
   -moz-transform: translate3d(20px, 0, 0);
    -ms-transform: translate3d(20px, 0, 0);
     -o-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
-webkit-transition: .2s ease .5s;
   -moz-transition: .2s ease .5s;
    -ms-transition: .2s ease .5s;
     -o-transition: .2s ease .5s;
        transition: .2s ease .5s;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

@media only screen and (max-width: 880px) {
  .timeline .swiper-slide .timeline-title {
	font-size: 60px;
	margin: 29px 0 27px 0;
  }
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-slide .timeline-title {
	font-size: 40px;
	margin: 32px 0 30px 0;
  }
}

.timeline .swiper-slide .timeline-text {
color: #fff;
line-height: 1.5;
-webkit-transform: translate3d(20px, 0, 0);
   -moz-transform: translate3d(20px, 0, 0);
    -ms-transform: translate3d(20px, 0, 0);
     -o-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
-webkit-transition: .2s ease .6s;
   -moz-transition: .2s ease .6s;
    -ms-transition: .2s ease .6s;
     -o-transition: .2s ease .6s;
        transition: .2s ease .6s;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.timeline .swiper-slide .sign-up-button {
text-align: right;
margin: 5px 0 0 0;
float: right;
-webkit-transform: translate3d(20px, 0, 0);
   -moz-transform: translate3d(20px, 0, 0);
    -ms-transform: translate3d(20px, 0, 0);
     -o-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
-webkit-transition: .2s ease .6s;
   -moz-transition: .2s ease .6s;
    -ms-transition: .2s ease .6s;
     -o-transition: .2s ease .6s;
        transition: .2s ease .6s;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-slide .sign-up-button {
    text-align: center;
	margin: 14px auto 0 auto;
    float: none;
  }
}

.timeline .swiper-slide-active .timeline-year {
-webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
     -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
-webkit-transition: .4s ease 1.6s;
   -moz-transition: .4s ease 1.6s;
    -ms-transition: .4s ease 1.6s;
     -o-transition: .4s ease 1.6s;
        transition: .4s ease 1.6s;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.timeline .swiper-slide-active .timeline-title {
-webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
     -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
-webkit-transition: .4s ease 1.7s;
   -moz-transition: .4s ease 1.7s;
    -ms-transition: .4s ease 1.7s;
     -o-transition: .4s ease 1.7s;
        transition: .4s ease 1.7s;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.timeline .swiper-slide-active .timeline-text,
.timeline .swiper-slide-active .sign-up-button {
-webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
     -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
-webkit-transition: .4s ease 1.8s;
   -moz-transition: .4s ease 1.8s;
    -ms-transition: .4s ease 1.8s;
     -o-transition: .4s ease 1.8s;
        transition: .4s ease 1.8s;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.timeline .swiper-pagination {
font-family: 'Poppins', sans-serif;
font-size: 15px;
line-height: normal;
font-style: italic;
text-transform: uppercase;
font-weight: 400;
letter-spacing: normal;
color: #fff;
text-shadow: 1px 1px 2px #000;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
height: 100%;
right: 15%!important;
margin-right: 5px;
-webkit-box-orient: vertical;
        box-orient: vertical;
-webkit-box-direction: normal;
   -ms-flex-direction: column;
       flex-direction: column;
-webkit-box-pack: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
        box-pack: center;
-webkit-justify-content: center;
        justify-content: center;
z-index: 1;
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-pagination {
    display: none;
	visibility: hidden;
  }
}

.timeline .swiper-pagination::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
top: 0;
left: -30px;
background-color: rgba(255, 255, 255, .25);
}

.timeline .swiper-pagination-bullet {
position: relative;
width: auto;
height: auto;
text-align: center;
background: transparent;
color: #fff;
margin: 15px 0!important;
}

.timeline .swiper-pagination-bullet::before {
content: "";
position: absolute;
width: 8px;
height: 8px;
top: 8px;
left: -32.5px;
background-color: #fff;
-webkit-border-radius: 0;
   -moz-border-radius: 0;
    -ms-border-radius: 0;
     -o-border-radius: 0;
        border-radius: 0;
-webkit-transform: scale(0);
   -moz-transform: scale(0);
    -ms-transform: scale(0);
     -o-transform: scale(0);
        transform: scale(0);
-webkit-transition: .2s;
   -moz-transition: .2s;
    -ms-transition: .2s;
     -o-transition: .2s;
        transition: .2s;
}

.timeline .swiper-pagination-bullet-active {
color: #fff;
}

.timeline .swiper-pagination-bullet-active::before {
-webkit-transform: scale(1);
   -moz-transform: scale(1);
    -ms-transform: scale(1);
     -o-transform: scale(1);
        transform: scale(1);
}

.timeline .swiper-button-next,
.timeline .swiper-button-prev {
width: 20px;
height: 20px;
background-size: 20px 20px;
top: 15%;
margin-top: 0;
-webkit-transition: .2s;
   -moz-transition: .2s;
    -ms-transition: .2s;
     -o-transition: .2s;
        transition: .2s;
z-index: 2;
}

.timeline .swiper-button-prev {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.timeline .swiper-button-next {
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.timeline .swiper-button-prev {
top: 15%;
left: auto;
right: 15%;
-webkit-transform: rotate(90deg) translate(0, 10px);
   -moz-transform: rotate(90deg) translate(0, 10px);
    -ms-transform: rotate(90deg) translate(0, 10px);
     -o-transform: rotate(90deg) translate(0, 10px);
        transform: rotate(90deg) translate(0, 10px);
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-button-prev {
	top: 50%;
	left: 6px;
	-webkit-transform: rotate(0deg) translate(0);
       -moz-transform: rotate(0deg) translate(0);
        -ms-transform: rotate(0deg) translate(0);
         -o-transform: rotate(0deg) translate(0);
            transform: rotate(0deg) translate(0);
  }
}

.timeline .swiper-button-prev:hover {
-webkit-transform: rotate(90deg) translate(-3px, 10px);
   -moz-transform: rotate(90deg) translate(-3px, 10px);
    -ms-transform: rotate(90deg) translate(-3px, 10px);
     -o-transform: rotate(90deg) translate(-3px, 10px);
        transform: rotate(90deg) translate(-3px, 10px);
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-button-prev:hover {
    -webkit-transform: translateX(-3px);
       -moz-transform: translateX(-3px);
        -ms-transform: translateX(-3px);
         -o-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}

.timeline .swiper-button-next {
top: auto;
bottom: 15%;
right: 15%;
-webkit-transform: rotate(90deg) translate(0, 10px);
   -moz-transform: rotate(90deg) translate(0, 10px);
    -ms-transform: rotate(90deg) translate(0, 10px);
     -o-transform: rotate(90deg) translate(0, 10px);
        transform: rotate(90deg) translate(0, 10px);
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-button-next {
	top: 50%;
	right: 6px;
	-webkit-transform: rotate(0deg) translate(0);
       -moz-transform: rotate(0deg) translate(0);
        -ms-transform: rotate(0deg) translate(0);
         -o-transform: rotate(0deg) translate(0);
            transform: rotate(0deg) translate(0);
  }
}

.timeline .swiper-button-next:hover {
-webkit-transform: rotate(90deg) translate(3px, 10px);
   -moz-transform: rotate(90deg) translate(3px, 10px);
    -ms-transform: rotate(90deg) translate(3px, 10px);
     -o-transform: rotate(90deg) translate(3px, 10px);
        transform: rotate(90deg) translate(3px, 10px);
}

@media only screen and (max-width: 768px) {
  .timeline .swiper-button-next:hover {
    -webkit-transform: translateX(3px);
       -moz-transform: translateX(3px);
        -ms-transform: translateX(3px);
         -o-transform: translateX(3px);
            transform: translateX(3px);
  }
}


/* 29.3. swiper thumbnail */
.swiper-slider-top,
.swiper-slider-top-thumbs-vertical {
width: 100%;
height: 100%;
}

.swiper-slider-bottom {
position: absolute;
width: 100%;
height: 15%;
left: 0;
bottom: 0;
background: #000;
padding: 0;
border-left: none;
border-top: 10px solid #000;
border-bottom: 10px solid #000;
border-right: none;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
     -o-box-sizing: border-box;
        box-sizing: border-box;
cursor: pointer;
z-index: 10;
}

.swiper-slider-bottom-thumbs-vertical {
position: absolute;
width: 20%;
height: 100%;
top: 0;
right: 0;
background: #000;
padding: 0;
border-left: 10px solid #000;
border-top: none;
border-bottom: none;
border-right: 10px solid #000;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
     -o-box-sizing: border-box;
        box-sizing: border-box;
z-index: 10;
}

.swiper-slider-bottom .swiper-slide {
width: 25%;
height: 100%;
        opacity: 0.35;
   -moz-opacity: 0.35;
-webkit-opacity: 0.35;
filter: alpha(opacity=35);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
}

.swiper-slider-bottom-thumbs-vertical .swiper-slide {
        opacity: 0.35;
   -moz-opacity: 0.35;
-webkit-opacity: 0.35;
filter: alpha(opacity=35);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
cursor: pointer;
}

.swiper-slider-bottom .swiper-slide-active,
.swiper-slider-bottom-thumbs-vertical .swiper-slide-active {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.swiper-button-next,
.swiper-button-prev {
display: none;
visibility: hidden;
}


/* 30. home IMG gallery carousel */
.home-page-img-gallery-carousel-wrapper-outer {
position: relative;
width: 100%;
height: 100%;
margin: 0;
padding: 0;
}

.home-page-img-gallery-carousel-wrapper-outer p {
color: #fff;
}

.home-page-img-gallery-carousel-item-1,
.home-page-img-gallery-carousel-item-2,
.home-page-img-gallery-carousel-item-3,
.home-page-img-gallery-carousel-item-4 {
position: relative;
width: 100%;
height: 100vh;
background-position: center center;
background-repeat: no-repeat;
-webkit-background-size: cover;
   -moz-background-size: cover;
    -ms-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
}


/* 30.1. home IMG gallery carousel items */
.home-page-img-gallery-carousel-title h4 {
font-family: 'Poppins', sans-serif;
font-size: 35px;
font-weight: 600;
text-transform: uppercase;
letter-spacing: 0.05em;
text-align: left;
color: #fff;
line-height: 1;
padding: 0;
margin-top: 11px;
text-shadow: 1px 1px 2px #000;
}

@media only screen and (max-width: 880px) {
  .home-page-img-gallery-carousel-title h4 {
    font-size: 18px;
	text-align: center;
	margin-top: 14px;
  }
}

@media only screen and (max-width: 640px) {
  .home-page-img-gallery-carousel-title h4 {
	padding: 0 0 22px 0;
  }
}

.home-page-img-gallery-carousel-spacer-top {
margin-top: 17px;
}

@media only screen and (max-width: 880px) {
  .home-page-img-gallery-carousel-spacer-top {
	margin-top: 19px;
  }
}

@media only screen and (max-width: 640px) {
  .home-page-img-gallery-carousel-spacer-top {
	margin-top: -3px;
  }
}

.home-page-img-gallery-carousel-spacer-bottom {
margin-top: 14px;
}

.home-page-img-gallery-carousel-wrapper {
position: relative;
background: none;
height: 100vh;
}

.home-page-img-gallery-carousel-wrapper::after {
content: "";
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
-webkit-transition: all 1s ease 0s;
   -moz-transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
     -o-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
background-color: rgba(0, 0, 0, .7);
}

.home-page-img-gallery-carousel-wrapper .home-page-img-gallery-carousel-text {
position: absolute;
width: 100%;
font-family: 'Raleway', sans-serif;
font-size: 14px;
line-height: 1.5;
font-style: normal;
font-weight: 400;
color: #fff;
 -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
     -o-box-sizing: border-box;
        box-sizing: border-box;
left: 0;
bottom: 40px;
padding: 0 60px;
z-index: 10;
}

@media only screen and (max-width: 880px) {
  .home-page-img-gallery-carousel-wrapper .home-page-img-gallery-carousel-text {
    font-size: 13px;
	text-align: center;
	padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .home-page-img-gallery-carousel-wrapper .home-page-img-gallery-carousel-text {
	padding: 0 15px;
  }
}

.home-page-img-gallery-carousel-wrapper .home-page-img-gallery-carousel-text .home-page-img-gallery-carousel-heading {
font-family: 'Raleway', sans-serif;
font-size: 10px;
line-height: 1.5;
font-style: normal;
font-weight: 400;
text-transform: uppercase;
text-shadow: 1px 1px 2px #000;
letter-spacing: 0.05em;
}

.home-page-img-gallery-carousel-wrapper .home-page-img-gallery-carousel-text .home-page-img-gallery-carousel-content {
max-height: 0;
        opacity: 0;
   -moz-opacity: 0;
-webkit-opacity: 0;
filter: alpha(opacity=0);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
overflow: hidden;
-webkit-transition: all 0.5s ease-out;
   -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
     -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
}

.home-page-img-gallery-carousel-wrapper:hover::after {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.home-page-img-gallery-carousel-wrapper:hover .home-page-img-gallery-carousel-content {
max-height: 600px;
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-transition: all 1s ease-in;
   -moz-transition: all 1s ease-in;
    -ms-transition: all 1s ease-in;
     -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
}


/* 31. Magnific Popup v1.1.0 CUSTOM */
.mfp-arrow-left:after {
font-family: "Ionicons";
content: "\f124";
font-size: 18px;
color: #fff;
margin-top: 22px;
}

.mfp-arrow-right:after {
font-family: "Ionicons";
content: "\f125";
font-size: 18px;
color: #fff;
margin-top: 22px;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
cursor: crosshair;
}

.mfp-arrow-left:after,
.mfp-arrow-left:before {
border-right: none;
}

.mfp-arrow-right:after,
.mfp-arrow-right:before {
border-left: none;
}

.mfp-title {
font-family: 'Raleway', sans-serif;
font-size: 13px;
line-height: 1.5;
font-style: normal;
font-weight: 400;
letter-spacing: 0.05em;
}

.popup-gallery-slider a:visited,
.popup-gallery-slider a:active,
.popup-gallery-slider a:focus {
color: #fff;
}

.popup-gallery-slider a:hover {
color: #000;
}


/* 32. typed text */
h1.typed-effect {
position: relative;
width: 70%;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 80px;
text-align: center;
text-transform: uppercase;
letter-spacing: -0.05em;
color: #fff;
margin: 0 auto;
padding: 0 50px;
line-height: 1;
}

@media only screen and (max-width: 1200px) {
  h1.typed-effect {
    width: 100%;
	padding: 0 5px;
  }
}

@media only screen and (max-width: 880px) {
  h1.typed-effect {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  h1.typed-effect {
    font-size: 25px;
  }
}


/* 32.1. typed text cursor */
.typed-cursor {
        opacity: 1;
   -moz-opacity: 1;
-webkit-opacity: 1;
filter: alpha(opacity=100);
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
-webkit-animation: blink 0.7s infinite;
   -moz-animation: blink 0.7s infinite;
        animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
